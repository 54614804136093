<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ operator.name }}
    </h5>
    <br>
    <b-field grouped>
      <a-simple-datepicker
        :manual-doc="userDoc"
        field="routes.operatorOverview.from"
        :label="$t('common.from')"
        expanded
        @change="fetchItems"
      />

      <a-simple-datepicker
        :manual-doc="userDoc"
        field="routes.operatorOverview.to"
        :label="$t('common.to')"
        @change="fetchItems"
      />
      <b-button
        position="is-right"
        icon-left="file-excel-box"
        @click="exportCsv"
      >
        {{ $t('common.navigation.export_to_csv') }}
      </b-button>
    </b-field>
    <div class="is-clearfix" />
    <!-- {{ chartItems }} -->
    <div
      v-if="items.length"
      class="columns"
    >
      <div class="column">
        <GChart
          type="PieChart"
          :data="chartTypesWithCount"
        />
      </div>

      <div class="column">
        <GChart
          type="ColumnChart"
          :data="stackedBarData"
          :options="{ isStacked: true }"
        />
      </div>
    </div>


    <b-table
      :data="items"
      :row-class="rowClass"
      :paginated="false"
    >
      <b-table-column
        v-slot="props"
        field="failure.name"
        :label="$t('failures.ref.name')"
      >
        <router-link
          :to="{ name: 'failure', params: { id: props.row.failure.id } }"
        >
          {{ props.row.failure.name }}
        </router-link>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="part.name"
        :label="$t('parts.ref.name')"
      >
        {{ props.row.part.name }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="number"
        :label="$t('common.number')"
      >
        {{ props.row.number }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="name"
        :label="$t('common.description')"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="resolution"
        :label="$t('common.resolution')"
      >
        {{ props.row.resolution }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$t('failureParts.options.state.received')"
      >
        {{ props.row.received }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$t('failureParts.options.state.repaired')"
      >
        {{ props.row.repaired }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        :label="$t('common.state')"
      >
        {{ props.row.state }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="failure.operator.name"
        :label="$t('operators.ref.name')"
      >
        {{ props.row.failure.operator.name }}
      </b-table-column>

      <empty-table slot="empty" />
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { orderBy, keys } from 'lodash'
import moment from 'moment'
import _ from 'lodash'
import { RoughStackedBar, RoughDonut } from 'vue-roughviz'
import ASimpleDatepicker from '@/components/Form/ASimpleDatepicker'
import EmptyTable from '@/components/EmptyTable'


const colors = ['coral', 'skyblue', '#66c2a5', 'tan', '#8da0cb',
                '#e78ac3', '#a6d854', '#ffd92f', 'coral', 'skyblue', 'tan', 'orange']


export default {
  components: {
    RoughDonut,
    RoughStackedBar,
    ASimpleDatepicker,
    EmptyTable
  },
  data() {
    return {
      userDoc: { module: 'user' },
      operator: null,
      chartKey: _.random(1,99999),
      colors
    }
  },
  computed: {
    ...mapGetters({
      items: 'operatorOverview/displayedItems',
      chartTypesWithCount: 'operatorOverview/chartTypesWithCount',
      stackedBarData: 'operatorOverview/stackedBarData'
    }),
    chartData() {
      return _.orderBy(this.stackedBarData, ['month'])
    },
    settings () {
      return this.user.routes.operatorOverview
    }
  },
  async created () {
    this.operator = await this.$store.dispatch('operators/fetchById', this.route.params.id)

    await this.fetchItems()
    this.chartKey = _.random(1,99999)

  },
  methods: {
    keys,
    moment,
    rowClass(item) {
      if(item.estimated_diff < 1) {
        return 'has-text-danger'
      } else if(item.estimated_diff < 8) {
        return 'has-text-warning'
      } else {
        return ''
      }
    },
    exportCsv() {
      const filename = `OperatorOverview ${this.operator.name}`
      this.$store.dispatch('operatorOverview/exportToCsv', { filename })
    },
    receivedDate(row) {
      const received_at = row.created_at || null
      return received_at ? moment(received_at).format('LL') : ''
    },
    repairedDate(row) {
      const repaired_at = row.progress.repaired.at || row.progress.completed.at || row.updated_at
      return repaired_at ? moment(repaired_at).format('LL') : ''
    },

    async fetchItems() {
      let where = [
        ['parent', '==', null],
        ['failure.operator.id', '==', this.route.params.id]
      ]

      await this.$store.dispatch('failureParts/openDBChannel', { where })
      await this.$store.dispatch('failureNodes/openDBChannel', { where })
      this.chartKey = _.random(1,99999)
    }
  }
}
</script>
